.aboutMe {
    margin: 0 auto;
    max-width: 1140px;
    min-height: 526px;
}

.aboutMe__title {
    margin: 0 auto;
    padding: 110px 0 23px;
    max-width: 1140px;
    min-height: 27px;

    color: #FFFFFF;

    font-weight: 400;
    font-size: 22px;
    text-align: left;

    border-bottom: #DADADA 1px solid;
}

.aboutMe__container {
    display: flex;
    justify-content: space-between;
}

.aboutMe__info {
    max-width: 600px;
    min-height: 327px;
    text-align: left;
    color: #FFFFFF;
}

.aboutMe__info-name {
    margin: 66px auto 0;
    min-height: 58px;

    font-weight: 400;
    font-size: 50px;
    line-height: 58px;
}

.aboutMe__info-prof {
    margin: 18px auto 0;
    min-height: 20px;

    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
}

.aboutMe__info-text {
    margin: 26px auto 0;
    min-height: 88px;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.aboutMe__info-web {
    margin: 99px auto 0;
    min-height: 17px;

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.aboutMe__info-photo {
    margin-top: 66px;
    max-width: 270px;
    height: 327px;
    border-radius: 10px;
  }

@media (max-width: 1220px) {
    .aboutMe {
        max-width: 1000px;
    }
}

@media (max-width: 1030px) {
    .aboutMe {
        max-width: 800px;
    }
}

@media (max-width: 830px) {
    .aboutMe {
        max-width: 700px;
    }
}

@media (max-width: 768px) {
    .aboutMe {
        max-width: 668px;
        min-height: 513px;
    }
    
    .aboutMe__title {
        padding-top: 90px;
        max-width: 668px;
        font-size: 22px;
    }
    
    .aboutMe__info {
        max-width: 364px;
        min-height: 373px;
    }
    
    .aboutMe__info-name {
        min-height: 40px;
        font-size: 40px;
        line-height: 40px;
    }
    
    .aboutMe__info-prof {
        margin-top: 16px;
        min-height: 18px;
        font-size: 12px;
        line-height: 18px;
    }
    
    .aboutMe__info-text {
        margin-top: 20px;
        min-height: 88px;
        font-size: 12px;
        line-height: 18px;
    }
    
    .aboutMe__info-web {
        margin-top: 87px;
        font-size: 14px;
        line-height: 17px;
    }
    
    .aboutMe__info-photo {
        max-width: 255px;
        height: 307px;
      }
}

@media (max-width: 720px) {
    .aboutMe {
        max-width: 540px;
        min-height: 834px;
    }
    
    .aboutMe__title {
        padding-top: 70px;
        padding-bottom: 28px;
        max-width: 540px;
        min-height: 22px;
        font-size: 18px;
    }

    .aboutMe__container {
        display: grid;
        justify-content: center;
    }
    
    .aboutMe__info {
        max-width: 540px;
        min-height: 301px;
        grid-row: 2;
    }
    
    .aboutMe__info-name {
        margin-top: 40px;
        min-height: 36px;
        font-size: 30px;
        line-height: 36px;
    }
    
    .aboutMe__info-prof {
        margin-top: 20px;
        min-height: 16px;
        font-size: 11px;
        line-height: 16px;
    }
    
    .aboutMe__info-text {
        min-height: 112px;
        font-size: 12px;
        line-height: 18px;
    }
    
    .aboutMe__info-web {
        margin-top: 40px;
        font-size: 14px;
        line-height: 17px;
    }

    .aboutMe__info-photo {
        margin-top: 60px;
        max-width: 540px;
        height: 352px;
        grid-row: 1;
      }
}

@media (max-width: 560px) {
    .aboutMe {
        max-width: 320px;
    }
    
    .aboutMe__title {
        max-width: 292px;
    }
 
    .aboutMe__info {
        max-width: 292px;
    }

    .aboutMe__info-photo {
        max-width: 292px;
      }
}
