@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.woff2?v=3.19") format("woff2"),
    url("Inter-Regular.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.woff2?v=3.19") format("woff2"),
    url("Inter-Medium.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.woff2?v=3.19") format("woff2"),
    url("Inter-Black.woff?v=3.19") format("woff");
}
