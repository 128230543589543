.card {
    margin: 40px auto 0;
    max-width: 1140px;
    min-height: 134px;
    display: flex;
    justify-content: space-between;

    background-color: #2F2F2F;
    list-style: none;
}

.card__description {
    margin: 22px auto 30px 22px;
    max-width: 400px;
    min-height: 48px;
}

.card__name {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: #FFFFFF;
}

.card__time {
    margin: 13px auto 30px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;

    color: #8B8B8B;
}

.card__like {
    width: 14px;
    height: 12px;
    margin: 0 auto 22px 22px;
    padding: 0;

    background-image: url(../../images/like.svg);
    background-repeat: no-repeat;
    background-color: #2F2F2F;
  
    border: none;
  
    cursor: pointer;
}

.card__like:hover {
    opacity: 60%;
    transition: 1s;
}

.card__like_active {
    background-image: url(../../images/likeRed.svg);
} 

.card__delete {
    width: 14px;
    height: 12px;
    margin: 0 auto 22px 22px;
    padding: 0;

    background-image: url(../../images/deleteLike.svg);
    background-repeat: no-repeat;
    background-color: #2F2F2F;
  
    border: none;
  
    cursor: pointer;
}

.card__link {
    margin: 6px;
    max-width: 220px;
    max-height: 122px;
    width: 100%;
    height: 100%;
}

.card__image {
    max-width: 220px;
    height: 122px;
    width: 100%;
}

@media (max-width: 1220px) {
    .card {
        max-width: 1000px;
    }
}

@media (max-width: 1020px) {
    .card {
        max-width: 800px;
    }
}

@media (max-width: 820px) {
    .card {
        max-width: 768px;
    }
}

@media (max-width: 720px) {
    .card {
        margin-top: 20px;
        max-width: 300px;
        min-height: 232px;
        flex-direction: column-reverse;
    }

    .card__content {
        display: grid;
        grid-template-columns: 228px 14px;
        gap: 30px;
    }
    
    .card__description {
        margin-top: 14px;
        margin-bottom: 14px;
        margin-left: 12px;
        max-width: 228px;
        min-height: 36px;
        width: 100%;
    }
    
    .card__name {
        font-size: 12px;
        line-height: 15px;
    }
    
    .card__time {
        margin-top: 8px;
        margin-bottom: 0;
    }
    
    .card__like {
        margin: 14px 0 0 0;
    }

    .card__delete {
        margin: 14px 0 0 0;
    }

    .card__link {
        margin: 0;
        max-width: 300px;
        min-height: 168px;
    }
    
    .card__image {
        max-width: 300px;
        min-height: 168px;
    }
}