.portfolio {
    margin: 0 auto 125px;
    max-width: 1140px;
    min-height: 425px;
}

.portfolio__title {
    margin: 0 auto;
    padding: 100px 0 30px;
    max-width: 1140px;
    min-height: 20px;

    color: #8B8B8B;

    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-align: left;
}

.portfolio__info {
    max-width: 1140px;
    display: flex;
    justify-content: space-between;
    border-bottom: #424242 1px solid;
}

.portfolio__info:hover {
    opacity: 0.7;
    transition: 1s;
}

.portfolio__name {
    margin: 0;
    padding: 20px 0 19px;
    max-width: 880px;
    min-height: 50px;

    font-weight: 400;
    font-size: 30px;
    line-height: 50px;
    text-align: left;

    color: #FFFFFF;
}

.portfolio__img {
    max-width: 29px;
}

@media (max-width: 1220px) {
    .portfolio {
        max-width: 1000px;
    }
}

@media (max-width: 1030px) {
    .portfolio {
        max-width: 800px;
    }
}

@media (max-width: 830px) {
    .portfolio {
        max-width: 700px;
    }
}

@media (max-width: 768px) {
    .portfolio {
        margin-bottom: 90px;
        max-width: 668px;
    }
    
    .portfolio__title {
        padding-top: 90px;
        max-width: 668px;
    }
    
    .portfolio__info {
        max-width: 668px;
    }
    
    .portfolio__name {
        max-width: 386px;
        min-height: 50px;
        font-size: 28px;
    }
    
    .portfolio__img {
        max-width: 27px;
    }
}

@media (max-width: 720px) {
    .portfolio {
        margin-bottom: 70px;
        max-width: 540px;
        min-height: 364px;
    }
    
    .portfolio__title {
        padding-top: 70px;
        max-width: 540px;
        font-size: 14px;
    }
    
    .portfolio__info {
        max-width: 540px;
    }
    
    .portfolio__name {
        max-width: 540px;
        min-height: 28px;
        font-size: 18px;
        line-height: 28px;
    }
    
    .portfolio__img {
        max-width: 18px;
    }
}

@media (max-width: 560px) {
    .portfolio {
        max-width: 292px;
    }
    
    .portfolio__title {
        max-width: 292px;
    }
    
    .portfolio__info {
        max-width: 292px;
    }
    
    .portfolio__name {
        max-width: 250px;
    }
}