.navigation {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: right;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
}

.navigation__content {
    max-width: 520px;   
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: #202020;  
    position: fixed;
}

.navigation__button {
    background-color: #202020; 
    border: none; 
}

.navigation__close {
    max-width: 22px;
    min-height: 22px;
    margin: 22.44px 22.44px 105.44px auto;
    align-items: flex-end;
}

.navigation__links {
    max-width: 340px;
    min-height: 122px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
}

.navigation__link {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: #FFFFFF;
}

.navigation__link:hover {
    opacity: 0.7;
    transition: 1s;
}

.navigation__bottom {
    max-width: 520px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.navigation__profile {
    margin-bottom: 90px;
}

.navigation__account {
    width: 100px;
    height: 32px;
    padding: 0;

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    background-color: #202020;
    color: #FFFFFF;
    border: #AAAAAA 2px solid;
    border-radius: 50px;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.navigation__account:hover {
    opacity: 0.8;
    transition: 1s;
}

@media (max-width: 320px) {
    .navigation {
        max-width: 320px;
        min-height: 780px;
    }
    
    .navigation__content {
        max-width: 320px;
    }
    
    .navigation__close {
        max-width: 22px;
        min-height: 22px;
        margin: 13.44px 13.44px 99.44px auto;
    }
    
    .navigation__links {
        max-width: 300px;
        min-height: 114px;
        gap: 24px;
    }

    .navigation__bottom {
        max-width: 300px;
    }
    
    .navigation__profile {
        margin-bottom: 46px;
    }
}